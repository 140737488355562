import React from "react";
import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Wrapper from "./components/Wrapper";
import CategoryDetail from "./pages/CategoryDetail";
import ProductDetail from "./pages/ProductDetail";
import Cart from "./pages/Cart";
import OrderDetail from "./pages/OrderDetail";
import Login from "./pages/Login";

function App() {
    return (
        <Routes>
            <Route element={<Wrapper />}>
                <Route path="/" element={<Home />} />
                <Route
                    path="/products/:productId"
                    element={<ProductDetail />}
                />
                <Route
                    path="/categories/:categoryId"
                    element={<CategoryDetail />}
                />
                <Route path="/cart" element={<Cart />} />
                <Route
                    path="/order-detail/:orderId"
                    element={<OrderDetail />}
                />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
}

export default App;
