// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".home_category_wrapper__4tKdn {\n    padding: 50px 0;\n}\n.home_wrapper__W9iJK {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    grid-auto-rows: 200px;\n    gap: 50px 40px;\n}   \n.home_categories__loader__4P0eZ {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n}\n@media only screen and (max-width: 610px) {\n    .home_wrapper__W9iJK {\n        grid-template-columns: 1fr;\n    }\n}\n@media only screen and (min-width: 610px) and (max-width: 1200px) {\n    .home_wrapper__W9iJK { \n        grid-template-columns: 1fr 1fr;\n    }\n}", "",{"version":3,"sources":["webpack://./src/pages/Home/home.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,aAAa;IACb,qCAAqC;IACrC,qBAAqB;IACrB,cAAc;AAClB;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;AACpC;AACA;IACI;QACI,0BAA0B;IAC9B;AACJ;AACA;IACI;QACI,8BAA8B;IAClC;AACJ","sourcesContent":[".category_wrapper {\n    padding: 50px 0;\n}\n.wrapper {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    grid-auto-rows: 200px;\n    gap: 50px 40px;\n}   \n.categories__loader {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n}\n@media only screen and (max-width: 610px) {\n    .wrapper {\n        grid-template-columns: 1fr;\n    }\n}\n@media only screen and (min-width: 610px) and (max-width: 1200px) {\n    .wrapper { \n        grid-template-columns: 1fr 1fr;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"category_wrapper": "home_category_wrapper__4tKdn",
	"wrapper": "home_wrapper__W9iJK",
	"categories__loader": "home_categories__loader__4P0eZ"
};
export default ___CSS_LOADER_EXPORT___;
