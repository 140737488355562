// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".spinner_spinner__ceNjm {\n    height: 50px;\n    width: 50px;\n    border-radius: 50%;\n    border-top: 5px solid var(--clr-black);\n    border-right: 5px solid var(--clr-black);\n    border-bottom: 5px solid var(--clr-rose);\n    border-left: 5px solid var(--clr-rose);\n    animation: spinner_spinner__ceNjm 1s linear infinite;\n}\n\n@keyframes spinner_spinner__ceNjm {\n    from {\n        transform: rotate(0deg);\n    }\n    to {\n        transform: rotate(360deg);\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/components/Spinner/spinner.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,sCAAsC;IACtC,wCAAwC;IACxC,wCAAwC;IACxC,sCAAsC;IACtC,oDAAqC;AACzC;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".spinner {\n    height: 50px;\n    width: 50px;\n    border-radius: 50%;\n    border-top: 5px solid var(--clr-black);\n    border-right: 5px solid var(--clr-black);\n    border-bottom: 5px solid var(--clr-rose);\n    border-left: 5px solid var(--clr-rose);\n    animation: spinner 1s linear infinite;\n}\n\n@keyframes spinner {\n    from {\n        transform: rotate(0deg);\n    }\n    to {\n        transform: rotate(360deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": "spinner_spinner__ceNjm"
};
export default ___CSS_LOADER_EXPORT___;
