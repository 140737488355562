import React from "react";
import Header from "../Header";
import { Navigate, Outlet } from "react-router-dom";
import SearchModal from "../SearchModal";
import CustomModal from "../CustomModal";
import { getAuth } from "../../utils/helper";

const Wrapper = () => {
    const auth = getAuth();
    return auth === "true" ? (
        <>
            <Header />
            <Outlet />
            <SearchModal />
            <CustomModal />
        </>
    ) : (
        <>
            <Navigate to="/login" />
            <Outlet />
        </>
    );
};

export default Wrapper;
