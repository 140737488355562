// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".categorydetail_grid__products__aBlsI {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n}\n.categorydetail_grid__product__CLhP6 {\n    flex-basis: 33.33333%;\n    padding: 15px;\n}\n.categorydetail_grid__products_loader__LhPQe {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n}\n@media only screen and (max-width: 610px) {\n    .categorydetail_grid__product__CLhP6 {\n        flex-basis: 100%;\n    }\n}\n@media only screen and (min-width: 610px) and (max-width: 1200px) {\n    .categorydetail_grid__product__CLhP6 {\n        flex-basis: 50%;\n    }\n}", "",{"version":3,"sources":["webpack://./src/pages/CategoryDetail/categorydetail.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,uBAAuB;AAC3B;AACA;IACI,qBAAqB;IACrB,aAAa;AACjB;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;AACpC;AACA;IACI;QACI,gBAAgB;IACpB;AACJ;AACA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".grid__products {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n}\n.grid__product {\n    flex-basis: 33.33333%;\n    padding: 15px;\n}\n.grid__products_loader {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n}\n@media only screen and (max-width: 610px) {\n    .grid__product {\n        flex-basis: 100%;\n    }\n}\n@media only screen and (min-width: 610px) and (max-width: 1200px) {\n    .grid__product {\n        flex-basis: 50%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid__products": "categorydetail_grid__products__aBlsI",
	"grid__product": "categorydetail_grid__product__CLhP6",
	"grid__products_loader": "categorydetail_grid__products_loader__LhPQe"
};
export default ___CSS_LOADER_EXPORT___;
