// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cart_cart__wrapper__OPzL8 {\n    display: flex;\n    flex-wrap: wrap;\n    overflow: hidden;\n    padding: 15px 0;\n    gap: 15px;\n}\n.cart_cart__cart_wrapper__ghnWz {\n    flex: 1 1;\n}\n.cart_cart__summary_wrapper__HlmrL {\n    flex: 1 1;\n    height: -moz-fit-content;\n    height: fit-content;\n    border: 1px solid var(--clr-rose);\n}\n.cart_cart__cart_empty__BlyNB {\n    height: 100%;\n    width: 100px;\n    margin: auto;\n}\n.cart_cart__cart_empty__BlyNB img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n    opacity: 0.7;\n}\n@media only screen and (max-width: 610px) {\n    .cart_cart__wrapper__OPzL8 {\n        flex-direction: column;\n    }\n}", "",{"version":3,"sources":["webpack://./src/pages/Cart/cart.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,SAAS;AACb;AACA;IACI,SAAO;AACX;AACA;IACI,SAAO;IACP,wBAAmB;IAAnB,mBAAmB;IACnB,iCAAiC;AACrC;AACA;IACI,YAAY;IACZ,YAAY;IACZ,YAAY;AAChB;AACA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,YAAY;AAChB;AACA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".cart__wrapper {\n    display: flex;\n    flex-wrap: wrap;\n    overflow: hidden;\n    padding: 15px 0;\n    gap: 15px;\n}\n.cart__cart_wrapper {\n    flex: 1;\n}\n.cart__summary_wrapper {\n    flex: 1;\n    height: fit-content;\n    border: 1px solid var(--clr-rose);\n}\n.cart__cart_empty {\n    height: 100%;\n    width: 100px;\n    margin: auto;\n}\n.cart__cart_empty img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n    opacity: 0.7;\n}\n@media only screen and (max-width: 610px) {\n    .cart__wrapper {\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cart__wrapper": "cart_cart__wrapper__OPzL8",
	"cart__cart_wrapper": "cart_cart__cart_wrapper__ghnWz",
	"cart__summary_wrapper": "cart_cart__summary_wrapper__HlmrL",
	"cart__cart_empty": "cart_cart__cart_empty__BlyNB"
};
export default ___CSS_LOADER_EXPORT___;
