// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".custommodal_modal_notification__cU1zd {\n  position: fixed;\n  top: 10px;\n  left: -100%;\n  opacity: 1;\n  z-index: 100;\n  padding: 5px;\n  min-width: 250px;\n  background-color: var(--clr-black);\n  border-radius: 10px;\n  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);\n  background-clip: padding-box;\n  transition: all 0.2s;\n  color: var(--clr-white);\n  text-align: center;\n}\n.custommodal_modal_notification__cU1zd.custommodal_modal_notification__visible__egXEx {\n  left: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/CustomModal/custommodal.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,SAAS;EACT,WAAW;EACX,UAAU;EACV,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,kCAAkC;EAClC,mBAAmB;EACnB,wCAAwC;EACxC,4BAA4B;EAC5B,oBAAoB;EACpB,uBAAuB;EACvB,kBAAkB;AACpB;AACA;EACE,UAAU;AACZ","sourcesContent":[".modal_notification {\n  position: fixed;\n  top: 10px;\n  left: -100%;\n  opacity: 1;\n  z-index: 100;\n  padding: 5px;\n  min-width: 250px;\n  background-color: var(--clr-black);\n  border-radius: 10px;\n  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);\n  background-clip: padding-box;\n  transition: all 0.2s;\n  color: var(--clr-white);\n  text-align: center;\n}\n.modal_notification.modal_notification__visible {\n  left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_notification": "custommodal_modal_notification__cU1zd",
	"modal_notification__visible": "custommodal_modal_notification__visible__egXEx"
};
export default ___CSS_LOADER_EXPORT___;
