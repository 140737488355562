import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./login.module.css";
import md5 from "md5";
import Cookies from "universal-cookie";
import { getAuth } from "../../utils/helper";

const Login = () => {
    const navigate = useNavigate();
    const cookies = new Cookies();
    const [password, setPassword] = useState("");
    const [invalid, setInvalid] = useState(false);
    const passwordChangHandler = (e) => {
        setPassword(e.target.value);
        setInvalid(false);
    };
    const loginHandler = () => {
        if (md5(password) === "fb4e2af7db97ca660a6a4a5ad76a51d6") {
            cookies.set("auth", "true", { path: "/" });
            return navigate("/");
        } else {
            setInvalid(true);
        }
    };

    useEffect(() => {
        const auth = getAuth();
        if (auth === "true") {
            return navigate("/");
        }
        return () => {};
    }, [navigate]);

    return (
        <div className={classes.wrapper}>
            <h3>Golden Ribbon</h3>

            <input
                placeholder="password"
                value={password}
                onChange={passwordChangHandler}
                type="password"
                autoComplete="false"
            />

            <button type="button" onClick={loginHandler}>
                Sign in
            </button>
            {invalid && <span>Invalid credential!</span>}
        </div>
    );
};

export default Login;
